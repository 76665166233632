<template>
  <v-dialog
    v-model="isMainBioDialog"
    hide-overlay
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on, attrs }">
      <!-- <div v-if="this.visitStore.visitData.status[1].value === 'inprogress'"> -->
      <button
        class="tw-w-full sm:tw-w-auto tw-mt-12 md:tw-mt-10 tw-bg-blue-500 tw-py-3 tw-px-6 tw-text-base tw-font-medium tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-800 hover:tw-bg-gray-700"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          left
          color="white"
        >
          {{ icons.mdiCursorDefaultClickOutline }}
        </v-icon> {{ textLabel }}
        <v-icon
          dark
          right
        >
          {{ icons.mdiArrowRight }}
        </v-icon>
      </button>
      <!-- </div> -->
    </template>
    <v-card tile>
      <v-toolbar
        dark
        color="primary"
        dense
        outlined
        flat
      >
        <v-toolbar-title>
          <span class="title">{{ this.visitStore.patientData.name }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          cols="12"
        >
          <fill-call-to-action
            :prev-button="false"
            @closeDialog="closeDialogBox($event)"
            @previous="previous($event)"
          ></fill-call-to-action>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {
    mdiClose, mdiFileDocumentEditOutline, mdiLanPending, mdiArrowRight, mdiAccountEdit
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'
import { splitFullName } from '@/util/functions'
import { statusColor, statusLabel } from '@/assets/lists'
import fillCallToAction from '@/components/Forms/fillCallToAction.vue'

export default {
    name: 'ProfileAction',
    components: {
        fillCallToAction,
    },
    props: {
        isMainBioDialogOpen: {
            type: Boolean,
            default: true,
        },
        emitStep: {
            type: Number,
            default: 1,
        },
        processStep: {
            type: Number,
            default: 1,
        },
        textLabel: {
            type: String,
            default: '',
        },
    },
    setup() {
        const visitStore = useVisitStore()

        // const isMainBioDialog = ref(false)

        // const isMainBioDialog = this.isMainBioDialogOpen

        return {
            // isMainBioDialog,
            visitStore,
            statusColor,
            statusLabel,
        }
    },
    data() {
        return {
            isMainBioDialog: this.isMainBioDialogOpen,
            activeStep: this.emitStep || 1, // FIXME swithc back to 1
            icons: {
                mdiClose,
                mdiFileDocumentEditOutline,
                mdiLanPending,
                mdiArrowRight,
                mdiAccountEdit
            },
        }
    },
    computed: {
        isBioDialogOpen(val) {
            if (this.activeStep === 1) {
                return true
            }
            if (val) {
                return false
            }

            return false
        },
        patient() {
            const splitName = splitFullName(this.visitStore.patientData.name)

            return splitName
        },
        patientData() {
            return this.visitStore.patientData
        },

    // isMainBioDialog() {
    //     return this.isMainBioDialogOpen
    // },
    },
    watch: {
        isMainBioDialogOpen(val) {
            this.isMainBioDialog = val
        },
        isMainBioDialog(val) {
            // this.$root.$emit('updateMainBioDialog', val)
            if (val) {
                this.$zendesk.hide()
            }

            // this.$zendesk.show()
        },
        emitStep(val) {
            if (val) {
                this.activeStep = val
            }
        },
    },
    mounted() {},
    methods: {
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                basic: data.basic || 'pending',

                // processing: data.processing || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
        },
        closeDialogBoxX(event) {
            this.isMainBioDialog = false
            this.$zendesk.show()

            this.$emit('updateMainBioDialog', false)

            // await this.closeDialog()
        },
        async closeDialogBox(event) {
            this.isMainBioDialog = false
            this.$zendesk.show()

            // this.$emit('updateMainBioDialog', false)
            await this.closeDialog()
        },
        async closeDialog() {
            await this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.patientData.dateofBirth })
            if (this.visitStore.patientData.id === this.visitStore.visitData.patientId) {
                // this.isMainBioDialogOpen = false
                if (this.visitStore.patientData?.detailedAddress && (this.visitStore.patientData?.hasAllergy === 0 || this.visitStore.patientData?.hasAllergy === 1) && (this.visitStore.patientData?.takesMeds === 0 || this.visitStore.patientData?.takesMeds === 1) && (this.visitStore.patientData?.insuranceStatus === 0 || this.visitStore.patientData?.insuranceStatus === 1)) {
                    const data = {
                        dateofBirth: this.visitStore.patientData.dateofBirth, // need to update this
                        visitId: this.visitStore.visitId, // need to update this
                        patientId: this.visitStore.patientData.id, // need to update this
                        basic: 'complete',

                    // processing: 'inprogress',
                    // payment: 'pending',
                    // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                } else {
                    const data = {
                        dateofBirth: this.visitStore.patientData.dateofBirth, // need to update this
                        visitId: this.visitStore.visitId, // need to update this
                        patientId: this.visitStore.patientData.id, // need to update this
                        basic: 'pending',

                        // processing: 'pending',
                        // payment: 'pending',
                        // delivery: 'pending',
                    }
                    this.updateVisitStatus(data)
                }
            }
            this.$emit('closeDialog', false)
            this.$emit('update:is-bio-dialog-open', false)
            this.$emit('updateMainBioDialog', false)
        },
        previous(step) {
            this.activeStep = step
        },
        next(step) {
            this.activeStep = step
        },
    },
}
</script>

<style lang="scss" scoped>
#profileContent, #medicalContent, #insranceContent, #fillOrderContent {
    top: 0;
    padding: 0px !important;
    flex: 1 0 auto;
    width: 100%;
}

#insranceContent {
  margin: -8px -28px -16px -4px;
}
</style>
